export const FeaturesData = [
  {
    icon: '/storage/MainView/Feature/integrity.png',
    title: 'Integrity',
    description: 'We provide a safe space where users are valued and privacy is protected.',
  },
  {
    icon: '/storage/MainView/Feature/inclusivity.png',
    title: 'Inclusivity',
    description: 'We embrace all people from christian faith background and similar values.',
  },
  {
    icon: '/storage/MainView/Feature/support.png',
    title: 'Support',
    description: 'We offer powerful tools and resources to help users build genuine connections .',
  },
  {
    icon: '/storage/MainView/Feature/growth.png',
    title: 'Growth',
    description: 'We foster growth through meaningful experiences.',
  },
  {
    icon: '/storage/MainView/Feature/custom-matches.png',
    title: 'Custom Matches',
    description: 'Find meaningful connections according to your interests and values.',
  },
]