<template>
  <div class="flex flex-col items-center">
    <Typography variant="h2" class="mb-6 desktop:mb-10">Key Features</Typography>
    <div class="w-full">
      <Slider
          :pagination="true"
          :slides="slides"
          :mobile-slides="2"
          :tablet-slides="3"
          :desktop-slides="5"
          :mobile-space-between="16"
          :tablet-space-between="16"
          :desktop-space-between="16" />
    </div>
    <Button class="min-w-52 mt-5 desktop:mt-10" @click="navigateTo(isAuthenticated ? '/swipes' : '/sign-up')">Try Now</Button>
  </div>
</template>

<script lang="ts" setup>
import { FeaturesData } from '~/views/MainView/contants/feature.js';
import { Slider } from '~/shared/ui/Slider';
import { Typography } from '~/shared/ui/Typography';
import { FeatureCard } from './components/FeatureCard';
import { Button } from '~/shared/ui/Button';

const { isAuthenticated } = useSanctumAuth()
const slides = FeaturesData.map(feature => ({
  component: FeatureCard,
  props: {
    icon: feature.icon,
    title: feature.title,
    description: feature.description,
  },
}));
</script>