<template>
  <div>
    <Typography variant="h2" class="mb-6 desktop:mb-10 text-center">User Testimonials</Typography>
    <Typography variant="body-1" tag="div" class="mb-6 desktop:mb-10 text-center text-brown">Hear from early adopters
      who
      are excited about their experiences with SlavicMeet
    </Typography>
    <Slider
        :pagination="true"
        :navigation="true"
        :slides="slides"
        :mobile-slides="1"
        :tablet-slides="1"
        :desktop-slides="3"
        :mobile-space-between="16"
        :tablet-space-between="16"
        :desktop-space-between="16"
    />
  </div>
</template>

<script setup lang="ts">
import { Typography } from '~/shared/ui/Typography';
import { Slider } from '~/shared/ui/Slider';
import { ReviewsData } from '../../contants/reviews';
import { ReviewCard } from './components/ReviewCard';

const slides = ReviewsData.map(review => ({
  component: ReviewCard,
  props: {
    title: review.title,
    content: review.content,
    picture: review.icon,
    date: review.date,
  }
}))
</script>