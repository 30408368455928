export const ReviewsData = [
  {
    icon: '/storage/MainView/Review/photo.png',
    title: 'Anna, 29',
    date: 'January 2024',
    content: '“SlavicMeet has been a game-changer for me. The platform’s secure environment made me feel comfortable connecting with others who share my values. The profile verification process gives me confidence that I’m meeting genuine people.”',
  },
  {
    icon: '/storage/MainView/Review/photo1.png',
    title: 'Ivan, 35',
    date: 'January 2024',
    content: '“SlavicMeet has been a game-changer for me. The platform’s secure environment made me feel comfortable connecting with others who share my values. The profile verification process gives me confidence that I’m meeting genuine people.”',
  },
  {
    icon: '/storage/MainView/Review/photo2.png',
    title: 'Open Sans',
    date: 'January 2024',
    content: '“SlavicMeet has been a game-changer for me. The platform’s secure environment made me feel comfortable connecting with others who share my values. The profile verification process gives me confidence that I’m meeting genuine people.”',
  },
]