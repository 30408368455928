<template>
  <label
      :class="[styles.root]"
      :for="wideRadioFieldId">
    <input
        :id="wideRadioFieldId"
        :class="[styles.input]"
        :name="name"
        type="radio"
        :value="modelValue">
    <Typography variant="p" tag="span" class="text-white">{{ label }}</Typography>
  </label>
</template>

<script lang="ts" setup>
import styles from './WideRadioField.module.scss'
import { Typography } from '~/shared/ui/Typography';
import { useId } from '#app';

const wideRadioFieldId = useId();

interface WideRadioFieldProps {
  modelValue?: string;
  label: string;
  name: string;
  disabled?: boolean;
}

withDefaults(defineProps<WideRadioFieldProps>(), {
  modelValue: null,
  disabled: false,
});
</script>