<template>
  <div :class="styles.root">
    <div :class="styles.wrapper">
      <Typography tag="div" variant="h3" :class="styles.text">What’s Your Ideal Match Type?</Typography>
      <Typography tag="div" variant="p" :class="styles.text">Let us know what you're looking for in a match</Typography>
      <div :class="styles.inputWrapper">
        <WideRadioField name="test" label="Someone with similar interests"/>
        <WideRadioField name="test" label="A partner for growth"/>
        <WideRadioField name="test" label="A companion for activities"/>
      </div>
      <Button :class="styles.button" size="large" @click="navigateTo(isAuthenticated ? '/swipes' : '/sign-up')">Find My Match</Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import styles from './MainFind.module.scss'
import { Typography } from '~/shared/ui/Typography';
import { Button } from '~/shared/ui/Button';
import { WideRadioField } from '~/shared/ui/Form/WideRadioField';

const { isAuthenticated } = useSanctumAuth()
</script>